/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

import { Cook } from '../../content/assets/icons/headers'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Heading1 from '../components/elements/heading1'
import BlogContainer from '../components/layout/blogContainer'
import PostsGrid from '../components/layout/postsGrid'

const CookPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMdx.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Cook" keywords={[`baking`, `cooking`, `desserts`]} />
      <BlogContainer>
        <div className="text-left">
          <div className="mt-24 max-h-24 md:max-h-32">
            <div className="ml-4 md:ml-0 h-24 md:h-32">
              <Cook />
            </div>
          </div>
          {/* <Heading1>Bake it</Heading1> */}
          {/* <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10"></h2> */}
          <hr className="mt-6"></hr>
          {/* <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 font-thin sm:mt-4">
              Mondays are for baking. Whether you're after a sweet treat, a warm
              loaf of bread, or anything inbetween, you'll find that here.
            </p> */}
        </div>
      </BlogContainer>
      <PostsGrid posts={posts} />
    </Layout>
  )
}

export default CookPage

export const cookPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: "Cook" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            featuredImageAlt
            featureText
            category
            tags
          }
        }
      }
    }
  }
`
