import React from 'react'

const Heading1 = props => {
  return (
    <h1
      className="text-4xl tracking-tight leading-10 font-medium text-gray-800 sm:text-5xl sm:leading-none md:text-6xl break-words"
      {...props}
    />
  )
}

export default Heading1
